<template>
  <div class="shipper_box">
    <div class="filter_Feature_Style">
      <div class="filter_Feature_items">
        <div class="filter_Feature_content">
          <div class="screen_title">姓名：</div>
          <el-input class="screenInput" size="mini" v-model.trim="params.userName" clearable></el-input>
        </div>
        <div class="filter_Feature_content">
          <div class="screen_title">手机号：</div>
          <el-input class="screenInput" size="mini" v-model.trim="params.mobile" clearable oninput="value=value.replace(/[^0-9]/g,'')"></el-input>
        </div>
      </div>
      <div class="query_btn">
        <el-button type="reset" size="mini" @click="reset">重 置</el-button>
        <el-button icon="el-icon-search" type="button" size="mini" @click="query">查 询</el-button>
      </div>
    </div>

    <div class="table_box">
      <el-table :data="tableData" border style="width: 100%" :header-cell-style="{ 
      background:'#ebeef5',hight:'50px',fontSize:'12px'}" v-loading="tableLoading" element-loading-text="数据正在加载中...">
        <el-table-column type="index" label="序号" align="center"></el-table-column>
        <el-table-column prop="userName" label="姓名" align="center"></el-table-column>
        <el-table-column prop="mobile" label="手机号" align="center">
          <template slot-scope="scope">
            <span class="details_style" @click="viewDetails(scope.row)">{{scope.row.mobile}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="identityVerify" label="身份证认证" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.identityVerify == 0">未认证</span>
            <span v-if="scope.row.identityVerify == 1">已认证</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="date" label="企业认证" align="center"></el-table-column> -->
        <el-table-column prop="createdDt" label="注册时间" align="center"></el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" small background :current-page="params.pageNum" :page-size="params.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { shipperList } from "@/api/basics";
import utils from "@/plugin/utils/utils";
export default {
  name:"/shipper/shipper",
  data() {
    return {
      params: {
        pageSize: 10,
        pageNum: 1,
        serviceType:40,
      },
      tableLoading: false,
      tableData: [],
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    query() {
      this.params.pageNum = 1;
      this.getList();
    },

    async getList() {
      try {
        let data = utils.removeNull(this.params); //去除空字符串
        let res = await shipperList(data);
        console.log(res, "res");
        if (res.retCode == "0000000") {
          this.tableLoading = false;
          this.tableData = res.rspBody.items;
          this.total = res.rspBody.total;
        } else {
          this.tableLoading = false;
          this.$message({
            type: "error",
            message: res.retDesc,
          });
        }
      } catch (error) {
        console.log(error);
        this.tableLoading = false;
        this.$message({
          type: "error",
          message: error.retDesc,
        });
      }
    },

    //重置
    reset() {
      this.params = utils.clearQuery(this.params);
      this.params.pageSize = 10;
      this.params.pageNum = 1;
      this.params.serviceType = 40;
      this.getList();
    },

    viewDetails(row) {
      console.log(row);
      let list = JSON.stringify(row);
      this.$router.push({
        path: "/shipper/shipperDetails",
        query: {
          row: list,
          type: "details",
        },
      });
    },

    handleSizeChange(val) {
      this.params.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.params.pageNum = val;
      this.getList();
    },
  },
};
</script>

<style lang="scss" scoped>
.shipper_box {
  width: 100%;
  height: 100%;
  .table_box {
    margin-top: 15px;

    .details_style {
      color: #086ffd;
      cursor: pointer;
    }
  }
}
</style>